import React from "react";
import "./SahitiProfile.css";

const sahitiProfile = () => {
  return (
    
    <section id="sahitiProfile" className="sahitiProfile-container">
      <div className="sahitiProfile-content">
        <h2>Delivering seamless user experiences.</h2>
        <p>
        Full Stack Developer | AWS Certified
        </p>
        <p>
        Welcome to my digital space! I am an accomplished full stack developer, 
        weaving seamless solutions with my proficiency in front-end and back-end technologies. 
        Explore my portfolio to witness the intersection of creativity and functionality. 
        Let's embark on a journey where innovation meets impact.
        </p>
      </div>
      <div className="tech-icon">
            <img src="./assets/images/AWS.png" alt="" />
          </div>

      <div className="sahitiProfile-img">
        <div>
          <img src="./assets/images/Img.jpg" alt="Sahiti Kathula" />
          
        </div>
        <h1>Sahiti Kathula</h1>
      </div>
      <script src="main.js"></script>
    </section>
   
  );
};

export default sahitiProfile;
