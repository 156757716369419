export const SKILLS = [
  {
    title: "Frontend",
    icon: "./assets/images/frontend-icon.png",
    skills: [
      { skill: "HTML5", percentage: "85%" },
      { skill: "CSS3", percentage: "90%" },
      { skill: "JavaScript", percentage: "80%" },
      { skill: "React.js", percentage: "80%" },
      { skill: "Angular 14", percentage: "70%" },
    ],
  },
  {
    title: "Backend",
    icon: "./assets/images/backend-icon.png",
    skills: [
      { skill: "Java(8,11,17)", percentage: "85%" },
      { skill: "SpringBoot", percentage: "80%" },
      { skill: "Node.js", percentage: "70%" },
      { skill: "Apache Kafka", percentage: "80%" },
      { skill: "MySQL", percentage: "70%" },
      { skill: "MongoDB", percentage: "65%" },

    ],
  },
  {
    title: "Other",
    icon: "./assets/images/others-icon.png",
    skills: [
      { skill: "AWS", percentage: "85%" },
      { skill: "DevOps", percentage: "70%" },
      { skill: "Testing", percentage: "65%" },
    ],
  },
  {
    title: "Soft Skills",
    icon: "./assets/images/soft-skills-icon.png",
    skills: [
      { skill: "Problem-Solving", percentage: "80%" },
      { skill: "Analytical Skills", percentage: "85%" },
      { skill: "Continuous Learning", percentage: "75%" },
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    title: "Sr. Java Full Stack Developer at UBS, Texas",
    date: "Jan 2022 - Till Date",
    responsibilities: [
      "Designed and implemented microservices using SpringBoot, facilitating modular and independently deployable components. Utilized containerization tools like Docker for packaging and deploying microservices.",
      "Designed and implemented event-driven architectures using Apache Kafka, ensuring efficient data processing and communication between microservices.",
      "Applied Java 8 features like Lambda Expressions, Collection and concurrency API improvements, functional interfaces, and object-oriented design principles to create modular Java code.",
      
    ],
  },
  {
    title: "Sr. Java Developer at General Motors, MI",
    date: "May 2022 - Dec 2022",
    responsibilities: [
      "Participated in the complete software development life cycle of Requirement gathering, Design, Analysis, and Code development, User Acceptance and Integration Testing, CR analysis, and Production support. ",
      "Led the migration process to Angular 14, addressing compatibility issues and leveraging new features and optimizations introduced in Angular 14.",
      "Designed and implemented an integration layer connecting Spring Boot microservices with Node.js modules, ensuring seamless communication between different sectors of the overall system.",
    ],
  },
  {
    title: "Java Developer at Softvan, India",
    date: "Feb 2019 - Aug 2021",
    responsibilities: [
      "Worked on AWS services using EC2, and S3 and managed and maintained the AWS infrastructure using various services such as ECS, ALB, DynamoDB, IAM, CloudWatch, CI/CD, SQS, SNS, Lambda, MKS, CloudFormation.",
      "Assisted in troubleshooting and resolving database-related issues and developed optimized SQL queries for integration with other applications.",
      "Integrated RESTful APIs developed in SpringBoot to fetch and display data within the mobile app and Integrated third-party APIs , such as payment gateways and social media integration.",
    ],
  },
  {
    title: "Java Developer at Daksh Solutions, India",
    date: "July 2018 to Jan 2019",
    responsibilities: [
      "Designed and implemented the user interface using HTML5, CSS3, JavaScript, Angular 8, and TypeScript and developed controller classes using Spring MVC, Spring Boot, handled security using Spring Security.",
      "Used Maven build tool to build and deploy the application and Jenkins for continuous integration and Deployed microservices Docker container.",
      "Extensively used SQL and PL/SQL scripting for stored procedures to interact with Oracle and Utilized JIRA for task scheduling, quality control activities like defect tracking and fixing developed using AGILE methodology.",
    ],
  },
  {
    title: "Java Developer at  Kriti InfoSystems, India",
    date: "July 2018 to Jan 2019",
    responsibilities: [
      "Implemented Java 8 features like Multithreading, Lambda Functions, Concurrency, Exception Handling, Generics and Collections whenever necessary and developed JSPs based on MVC pattern using Spring Framework",
      "Implemented responsive and dynamic user interfaces using React Native for cross-platform compatibility and worked on projects involving jQuery, Ajax.",
      "Developed dynamic web pages using HTML5, CSS3, jQuery, Bootstrap, CSS3 (SASS) and AJAX and Written JUNIT tests as per the use case and used Mockito framework to confirm the expected results.",
    ],
  }
];
